
export default {
  name: "Footer",
  computed: {
    year() {
      const now = new Date()
      const yyyy = now.getFullYear()
      return yyyy
    }
  }
}
